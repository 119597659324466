import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/path.service";
var FilesService = /** @class */ (function () {
    function FilesService(http, pathSvc) {
        this.http = http;
        this.pathSvc = pathSvc;
        this.port = environment.port;
    }
    FilesService.prototype.upload = function (formData, domain) {
        return this.http.post("".concat(this.pathSvc.setPath(domain, this.port, false), "/api/storage/file/upload"), formData);
    };
    FilesService.prototype.publish = function (formData, domain) {
        var path = "".concat(this.pathSvc.setPath(domain, this.port, true), "/api/home/files");
        if (window.location.hostname.includes('localhost')) {
            path = "http://localhost:3010/api/home/files";
        }
        return this.http.post(path, formData);
    };
    FilesService.prototype.viewFile = function (link, domain) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, false), "/api/home/files/view/").concat(link), { responseType: 'arraybuffer' });
    };
    FilesService.ɵfac = function FilesService_Factory(t) { return new (t || FilesService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PathService)); };
    FilesService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: FilesService, factory: FilesService.ɵfac, providedIn: 'root' });
    return FilesService;
}());
export { FilesService };
