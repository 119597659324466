import { EventEmitter, } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fromIntersectionObserver, } from './from-intersection-observer';
import * as i0 from "@angular/core";
var IntersectionObserverDirective = /** @class */ (function () {
    function IntersectionObserverDirective(element) {
        this.element = element;
        this.intersectionDebounce = 0;
        this.intersectionRootMargin = '0px';
        this.visibilityChange = new EventEmitter();
        this.destroy$ = new Subject();
    }
    IntersectionObserverDirective.prototype.ngOnInit = function () {
        var _this = this;
        var element = this.element.nativeElement;
        var config = {
            root: this.intersectionRoot,
            rootMargin: this.intersectionRootMargin,
            threshold: this.intersectionThreshold,
        };
        fromIntersectionObserver(element, config, this.intersectionDebounce)
            .pipe(takeUntil(this.destroy$))
            .subscribe(function (status) {
            _this.visibilityChange.emit(status);
        });
    };
    IntersectionObserverDirective.prototype.ngOnDestroy = function () {
        this.destroy$.next(null);
    };
    IntersectionObserverDirective.ɵfac = function IntersectionObserverDirective_Factory(t) { return new (t || IntersectionObserverDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    IntersectionObserverDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: IntersectionObserverDirective, selectors: [["", "intersectionObserver", ""]], inputs: { intersectionDebounce: "intersectionDebounce", intersectionRootMargin: "intersectionRootMargin", intersectionRoot: "intersectionRoot", intersectionThreshold: "intersectionThreshold" }, outputs: { visibilityChange: "visibilityChange" } });
    return IntersectionObserverDirective;
}());
export { IntersectionObserverDirective };
