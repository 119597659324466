import { __read, __spreadArray } from "tslib";
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../shared/services/path.service";
var PrivateBlogService = /** @class */ (function () {
    function PrivateBlogService(http, pathSvc) {
        this.http = http;
        this.pathSvc = pathSvc;
        this.postBlogUrl = environment.blogPathName;
        this.port = environment.port;
        this.blogSource = new BehaviorSubject([]);
        this.posts$ = this.blogSource.asObservable();
    }
    PrivateBlogService.prototype.upsert = function (domain, blog, language) {
        return this.http.post("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(this.postBlogUrl, "/post/?language=").concat(language), blog);
    };
    PrivateBlogService.prototype.list = function (domain, groupId, language, from, size) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(this.postBlogUrl, "/post/?groupId=").concat(groupId, "&language=").concat(language, "&from=").concat(from, "&size=").concat(size));
    };
    PrivateBlogService.prototype.remove = function (domain, id) {
        return this.http.delete("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(this.postBlogUrl, "/post/").concat(id));
    };
    PrivateBlogService.prototype.upsertPost = function (post) {
        var posts = this.blogSource.getValue();
        var index = posts.findIndex(function (el) { return el.id === post.id; });
        if (index !== -1) {
            posts[index] = post;
            this.blogSource.next(posts);
        }
        else {
            this.blogSource.next(__spreadArray(__spreadArray([], __read(this.blogSource.getValue()), false), [post], false));
        }
    };
    PrivateBlogService.prototype.removePost = function (id) {
        var posts = this.blogSource.getValue();
        var index = posts.findIndex(function (el) { return el.id === id; });
        if (index !== -1) {
            posts.splice(index, 1);
            this.blogSource.next(posts);
        }
    };
    PrivateBlogService.prototype.setPosts = function (posts) {
        this.blogSource.next(posts);
    };
    PrivateBlogService.prototype.addPosts = function (posts) {
        var current = this.blogSource.getValue();
        current.push.apply(current, __spreadArray([], __read(posts), false));
        this.blogSource.next(current);
    };
    PrivateBlogService.ɵfac = function PrivateBlogService_Factory(t) { return new (t || PrivateBlogService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PathService)); };
    PrivateBlogService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PrivateBlogService, factory: PrivateBlogService.ɵfac, providedIn: 'root' });
    return PrivateBlogService;
}());
export { PrivateBlogService };
