import { __assign } from "tslib";
import { BehaviorSubject, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "./settings.service";
var TranslateService = /** @class */ (function () {
    function TranslateService(settingsSvc) {
        this.settingsSvc = settingsSvc;
        this.translations = {};
        this.mainTranslations = typeof translations !== 'undefined' ? translations : { en: {}, bg: {} };
        this.currentLang = new BehaviorSubject('en');
        this.availableLangs = ['en', 'bg'];
        this.privateTranslations = {
            en: {
                STORAGE: 'Files',
                WAREHOUSE: 'Warehouse',
                SETTINGS: 'Settings',
                BUILDER: 'Builder',
                UPLOAD: 'Upload',
                PROFILE: 'Profile',
                LOGOUT: 'Logout',
                LOGIN: 'Login',
                SUBMIT: 'Submit',
                UPLOAD_TEXT1: 'Click to upload',
                FORGOT: 'Forgot password',
                UPLOAD_TEXT2: 'or drag and drop',
                SIGNIN: 'Sign in to your account',
                EMAIL: 'Your email',
                PASSWORD: 'Password',
                SIGNIN_BTN: 'Sign in',
                WRONG_PASSWORD: 'You have entered wrong password or email!',
                CREATE_POST: 'Create/Edit Post',
                SELECT_POST: 'Select Post',
                SHORT_POST: 'Short Post',
                FULL_POST: 'Full Post',
                HEADING: 'Heading',
                DESCRIPTION: 'Description',
                DESIGN: 'Design',
                ADD_OPTION: 'Add and option',
                ALL_POST: 'All Posts',
                APPLY: 'Apply',
                HEADING_REQUIRED: 'Heading is required',
                IMAGE_REQUIRED: 'Image is required',
                DESCRIPTION_REQUIRED: 'Description is required',
                DESIGN_REQUIRED: 'Design is required',
                ERROR_UNZIP: 'Error unzipping file:',
                EXISTING_FILES: 'Existing files with unsuported file type, please delete them to proceed!',
                UPLOAD_TEXT3: 'Are you sure you want to upload to',
                SUCCESS: 'Success!',
                CLONE: 'Do you want to clone this template?',
                DATE: 'Date of creation',
                DATE_REQUIRED: 'Date is required',
                ENABLE_RECENT_POSTS: 'Enable recent posts',
                ENABLE_PREVIOUS_NEXT: 'Enable previous next posts',
                ENABLE_MORE_ARTICLES: 'Enable more articles',
                TEMPLATES_ALL: 'All',
                TEMPLATES: 'Templates',
                TEMPLATES_SEARCH: 'Search for sites by name or id',
                TEMPLATES_OPEN: 'Open',
                TEMPLATES_NEW: 'Enter new page name',
                TEMPLATES_CREATE: 'Create',
                TEMPLATES_HELP: 'Select a template, enter project name, then click create. If no template is selected a blank project will be created.',
                TEMPLATES_INFO: 'Site Info',
                TEMPLATE_UPDATED: 'Last Updated',
                TEMPLATES_PAGES: 'Pages',
                TEMPLATES_CREATED: 'Created At',
                TEMPLATES_SIZE: 'Size',
                TEMPLATES_ACTIONS: 'Actions',
                TEMPLATES_OPEN_TITLE: 'Select to open site',
                TEMPLATES_SORTBY_NAME_TITLE: 'Click to sort by site name',
                TEMPLATES_SORT_BY_DATE_TITLE: 'Click to sort by last update date',
                TEMPLATES_SORT_BY_PAGES_TITLE: 'Click to sort by number of pages',
                TEMPLATES_SORT_BY_CREATE_TITLE: 'Click to sort by site creation date',
                TEMPLATES_SORT_BY_SIZE_TITLE: 'Click to sort by site size',
                TEMPLATES_SORT_BY_ACTIONS_TITLE: 'Click to sort by site name',
                TEMPLATES_DELETE_TITLE: 'Delete',
                TEMPLATES_EDIT_TITLE: 'Edit',
                TEMPLATE_SETTINGS_THUMBNAIL_HELP: 'Enter url, or generate thumbnail.',
                TEMPLATE_SETTINGS_THUMBNAIL: 'Thumbnail',
                TEMPLATE_SETTINGS_GENERATE: 'Generate',
                TEMPLATE_SETTINGS_NAME: 'Name',
                TEMPLATE_SETTINGS_DESCRIPTION: 'Description',
                TEMPALTE_SETTINGS_ATTRIBUTES: 'Attributes',
                TEMPLATE_SETTINGS_META_TAGS: 'Meta tags',
                TEMPLATE_SETTINGS_ICON: 'Icon',
                TEMPLATE_SETTINGS_LANGUAGES: 'Languages',
                TEMPLATE_SETTINGS_CURRENCIES: 'Currencies',
                TEMPLATE_SETTINGS_THEME: 'Theme',
                TEMPLATE_SETTINGS_TEMPLATE: 'Template',
                TEMPLATE_SETTINGS_COMPONENTS: 'Components',
                TEMPLATE_SETTINGS_SHARED: 'Shared',
                TEMPLATE_SETTINGS_SAVE: 'Save',
                TEMPLATE_SETTINGS_THUMBNAIL_PLACEHOLDER: 'Thumbnail',
                TEMPLATE_SETTINGS_NAME_PLACEHOLDER: 'Name...',
                TEMPLATE_SETTINGS_DESCRIPTION_PLACEHOLDER: 'Description...',
                TEMPLATE_SETTINGS_ATTRIBUTES_PLACEHOLDER: 'Some attribute...',
                TEMPLATE_SETTINGS_META_TAGS_PLACEHOLDER: 'Some meta...',
                TEMPLATE_SETTINGS_Icon_PLACEHOLDER: '<link rel=',
                NEW_PAGE: 'New Page +',
                NEW_PAGE_NAME: 'page name',
                SAVE: 'Save',
                PRIVATE: 'Private',
                DEFAULT: 'Default',
                NAME: 'Name',
                TITLE: 'Title',
                ATTRIBUTES: 'Attributes',
                META: 'Meta tags',
                NAMEPLACEHOLDER: 'Name...',
                TITLEPLACEHOLDER: 'Title...',
                METAPLACEHOLDER: '<meta name=',
                ATTRIBUTESPLACEHOLDER: 'Some atribute...',
                COMPONENTS: 'Components',
                SIDENAV: 'Sidenav',
            },
            bg: {
                STORAGE: 'Файлове',
                WAREHOUSE: 'Склад',
                SETTINGS: 'Настройки',
                BUILDER: 'Билдър',
                UPLOAD: 'Качване',
                PROFILE: 'Профил',
                LOGOUT: 'Изход',
                LOGIN: 'Вход',
                SUBMIT: 'Потвърди',
                FORGOT: 'Забравена парола',
                UPLOAD_TEXT1: 'Кликни за качване',
                UPLOAD_TEXT2: 'или дръпни и постави',
                SIGNIN: 'Влезте в акаунта си',
                EMAIL: 'Вашият имейл',
                PASSWORD: 'Парола',
                SIGNIN_BTN: 'Вход',
                WRONG_PASSWORD: 'Въвели сте грешна парола или имейл!',
                CREATE_POST: 'Създаване/редактиране на публикация',
                SELECT_POST: 'Избор на публикация',
                SHORT_POST: 'Кратка публикация',
                FULL_POST: 'Цялата публикация',
                HEADING: 'Заглавие',
                DESCRIPTION: 'Описание',
                DESIGN: 'Дизайн',
                ADD_OPTION: 'Добавяне на опция',
                ALL_POST: 'Всички публикации',
                APPLY: 'Приложи',
                HEADING_REQUIRED: 'Заглавие е задължително',
                IMAGE_REQUIRED: 'Снимка е задължително',
                DESCRIPTION_REQUIRED: 'Описанието е задължително',
                DESIGN_REQUIRED: 'Дизайн е задължително',
                ERROR_UNZIP: 'Възникна грешка при разархивирането на файла:',
                EXISTING_FILES: 'Съществуващи файлове с неподдържан файлов тип, моля, изтрийте ги, за да продължите!',
                UPLOAD_TEXT3: 'Сигурни ли сте, че искате да качите в',
                SUCCESS: 'Успешно!',
                CLONE: 'Желаете ли да клонирате този шаблон?',
                DATE: 'Дата на създаване',
                DATE_REQUIRED: 'Дата е задължителна',
                ENABLE_RECENT_POSTS: 'Покажи последните публикации',
                ENABLE_PREVIOUS_NEXT: 'Покажи предишен и следващ пост',
                ENABLE_MORE_ARTICLES: 'Покажи още публикации',
                TEMPLATES_ALL: 'Всички',
                TEMPLATES: 'Шаблони',
                TEMPLATES_SEARCH: 'Търсене на сайтове по име или идентификатор',
                TEMPLATES_OPEN: 'Отвори',
                TEMPLATES_NEW: 'Въведете ново име на страница',
                TEMPLATES_CREATE: 'Създайте',
                TEMPLATES_HELP: 'Изберете шаблон, въведете името на проекта, след което щракнете върху създаване. Ако не е избран шаблон, ще бъде създаден празен проект.',
                TEMPLATES_INFO: 'Информация за сайта',
                TEMPLATE_UPDATED: 'Последна актуализация',
                TEMPLATES_PAGES: 'Страници',
                TEMPLATES_CREATED: 'Създаден на',
                TEMPLATES_SIZE: 'Размер',
                TEMPLATES_ACTIONS: 'Действия',
                TEMPLATES_OPEN_TITLE: 'Изберете, за да отворите сайта',
                TEMPLATES_SORTBY_NAME_TITLE: 'Кликнете, за да сортирате по име на сайта',
                TEMPLATES_SORT_BY_DATE_TITLE: 'Кликнете, за да сортирате по дата на последна актуализацияэ',
                TEMPLATES_SORT_BY_PAGES_TITLE: 'Кликнете, за да сортирате по брой страници',
                TEMPLATES_SORT_BY_CREATE_TITLE: 'Кликнете, за да сортирате по дата на създаване на сайта',
                TEMPLATES_SORT_BY_SIZE_TITLE: 'Кликнете, за да сортирате по размер на сайта',
                TEMPLATES_SORT_BY_ACTIONS_TITLE: 'Кликнете, за да сортирате по име на сайта',
                TEMPLATES_DELETE_TITLE: 'Изтрий',
                TEMPLATES_EDIT_TITLE: 'Редактиране',
                TEMPLATE_SETTINGS_THUMBNAIL_HELP: 'Въведете url или генерирайте снимка.',
                TEMPLATE_SETTINGS_THUMBNAIL: 'Снимка',
                TEMPLATE_SETTINGS_GENERATE: 'Генерирай',
                TEMPLATE_SETTINGS_NAME: 'Име',
                TEMPLATE_SETTINGS_DESCRIPTION: 'Описание',
                TEMPALTE_SETTINGS_ATTRIBUTES: 'Атрибути',
                TEMPLATE_SETTINGS_META_TAGS: 'Мета тагове',
                TEMPLATE_SETTINGS_ICON: 'Икона',
                TEMPLATE_SETTINGS_LANGUAGES: 'Езици',
                TEMPLATE_SETTINGS_CURRENCIES: 'Валути',
                TEMPLATE_SETTINGS_THEME: 'Тема',
                TEMPLATE_SETTINGS_TEMPLATE: 'Шаблон',
                TEMPLATE_SETTINGS_COMPONENTS: 'Компоненти',
                TEMPLATE_SETTINGS_SHARED: 'Споделено',
                TEMPLATE_SETTINGS_SAVE: 'Запази',
                TEMPLATE_SETTINGS_THUMBNAIL_PLACEHOLDER: 'Снимка',
                TEMPLATE_SETTINGS_NAME_PLACEHOLDER: 'Име...',
                TEMPLATE_SETTINGS_DESCRIPTION_PLACEHOLDER: 'Описание...',
                TEMPLATE_SETTINGS_ATTRIBUTES_PLACEHOLDER: 'Атрибут...',
                TEMPLATE_SETTINGS_META_TAGS_PLACEHOLDER: 'Мета...',
                TEMPLATE_SETTINGS_Icon_PLACEHOLDER: '<link rel=',
                NEW_PAGE: 'Нова Страница +',
                NEW_PAGE_NAME: 'име на страница',
                SAVE: 'Запази',
                PRIVATE: 'Частна',
                DEFAULT: 'По подразбиране',
                NAME: 'Име',
                TITLE: 'Заглавие',
                ATTRIBUTES: 'Атрибути',
                META: 'Мета тагове',
                NAMEPLACEHOLDER: 'Име...',
                TITLEPLACEHOLDER: 'Заглавие...',
                METAPLACEHOLDER: '<Мета име=',
                ATTRIBUTESPLACEHOLDER: 'Някакъв атрибут...',
                COMPONENTS: 'Компоненти',
                SIDENAV: 'Навигация',
            },
        };
        this.publicTranslations = {
            en: {
                FOOTER_TERMS: 'Terms and conditions',
                FOOTER_DELIVERY: 'Delivery',
                FOOTER_RETURNS: 'Returns',
                CHECKOUT_AGRREE_TERMS: 'Please agree terms and conditions!',
                SHOP_CATEGORIES: 'Categories',
                CHECKOUT_ADDRESS: 'Address',
                CHECKOUT_COUNTRY: 'Country',
                SHOP_NO_PRODUCTS: 'No products found!',
                TEXT_LOADING: 'It will take a few moments to find your domain...',
                SHOP_PRICE: 'Price',
                CHECKOUT_SIZE: 'Size',
                SUBSCRIPTION_DOMAIN_PRICE: 'Domain Price',
                INFO: 'If you subscribe for hosting with us, you get the domain for free!',
                CHECKOUT_PAYMENT: 'Payment',
                CHECKOUT_WITH: "by",
                CHECKOUT_TITLE: 'Checkout',
                CHECKOUT_CORRECT_DATA: 'Please enter valid data!',
                CHECKOUT_ERROR: 'Error',
                DOMAIN_BUY_TABLE: 'BUY DOMAIN',
                SUBSCRIPTION_CONFIRM: 'Confirm',
                SUBSCRIPTION_DOMAIN_INFO: 'In case of transfer, the domain is extended by one year',
                CHECKOUT_ADDRESS_VALID: 'Please enter address!',
                CHECKOUT_CREDIT_CARD: 'Credit card',
                'CHECKOUT_FIRM ADDRESS_REQUIRED': 'Firm address is required!',
                ENTER_DOMAIN: 'Please enter domain name',
                CHECKOUT_FINISHING_PAYMENT: 'Finishing payment',
                SHOP_DONATE: 'Please enter the amount you wish to pay!',
                CHECKOUT_INVOICE_INFO: 'Invoice information',
                SHOP_ALL: 'All',
                DOMAIN_HOSTING_TABLE: 'BUY WITH HOSTING',
                DOMAIN_FIND_PERFECT: 'Find the perfect domain name',
                CHECKOUT_LNAME: 'Last Name',
                SHOP_SUCCESS: 'Succesfully added to cart!',
                SUBSCRIPTION_DOMAIN_TYPE: 'Transfer domain',
                TRANSFER_KEY_REQUIRED: 'Transfer key is required',
                SHOP_VALID_PRICE: 'Please enter valid price!',
                SHOP_CATEGORY: 'Category',
                CHECKOUT_EMAIL_REQUIRED: 'Email is required',
                SHOP_FROM: 'From',
                SUBSCRIPTION_HAVE_DOMAIN: 'Transferring an owned domain',
                SEARCH: 'Search',
                AUTOCHARGE_CONFIRM: 'Confirm',
                SHOP_ADDTOBAG: 'Add to cart',
                CHECKOUT_FIRM_ADDRESS: 'Firm address',
                CHECKOUT_PHONE: 'Phone',
                SUBSCRIPTION_KEY: 'Transfer key',
                SUBSCRIPTION_DOMAIN_NAME: 'Domain name',
                CHECKOUT_SHIPPING_INFORMATION: 'Shipping information',
                CHECKOUT_ORDER_SUMMARY: 'Order summary',
                CHECKOUT_FNAME: 'First name',
                CHECKOUT_AGREE: 'I agree to the',
                AVAILABLE_DOMAIN: 'Domain is available',
                CHECKOUT_CREATING_PAYMENT: 'Creating payment',
                CHECKOUT_UIC_REQUIRED: 'UIC/UBI is required',
                DOMAIN_FREE: 'Free',
                CHECKOUT_CLOSE_INVOICE: 'Close if you do not want an invoice!',
                DOMAIN_FORMAT: 'Invalid domain format',
                SUBSCRIPTION_BACK: 'Back',
                SHOP_DESCRIPTION: 'Description',
                CHECKOUT_VAT_REQUIRED: 'VAT is required',
                CHECKOUT_MINIMUM_PRICE: 'The order has not reached the minimum amount!',
                CHECKOUT_FNAME_REQUIRED: 'First name is required!',
                CHECKOUT_EMAIL_VALID: 'Please enter valid email address',
                CHECKOUT_PROCESSING_PAYMENT: 'Processing payment',
                SUBSCRIPTION_NEXT: 'Next',
                SHOPPING_CART_BTN: 'Checkout',
                CHECKOUT_PHONE_REQUIRED: 'Phone is required!',
                CHECKOUT_CARD_TITLE: 'Card',
                CHECKOUT_ADDRESS_REQUIRED: 'Address is required!',
                CHECKOUT_COUNTRY_REQUIRED: 'Please select country!',
                CHECKOUT_UIC: 'UIC/UBI',
                SHOPPING_CART_TITLE: 'Shopping cart',
                CHECKOUT_PAYED: 'Succesfully payed purchase!',
                CHECKOUT_FIRMNAME_REQUIRED: 'Firm name is required!',
                CHECKOUT_FIRM_ADDRESS_VALID: 'Please enter firm address!',
                SHOP_SIZE: 'Size',
                AUTOCHARGE_TITLE3DS: 'A 3D password is required to complete the payment',
                CHECKOUT_PHONE_VALID: 'Please enter valid phone number',
                CHECKOUT_TERMS: 'terms and conditions',
                AUTOCHARGE_TITLENEW: 'Please use the card you want to pay with',
                CHECKOUT_PRODUCTS_TITLE: 'Products',
                SUCCESS: 'Success',
                CHECKOUT_EMAIL: 'Email',
                DOMAIN_NAME_TABLE: 'DOMAIN NAME',
                CHECKOUT_PAY_BTN: 'Confirm order',
                CHECKOUT_CONTACT_INFORMATION: 'Contact information',
                SUBSCRIPTION_DOMAIN: 'New domain',
                AVAILABLE: 'Loading availables',
                MORE: 'Load more',
                CHECKOUT_VAT: 'VAT',
                SHOPPING_CART_EMPTY: 'Shopping cart is empty!',
                FOOTER_CREATED: 'Created by Social Cloud',
                CHECKOUT_DESCRIPTION: 'Additional description',
                CHECKOUT_SUBSCRIBE: "Subscribe me to updates",
                CHECKOUT_DELIVERY: '*Price does not include delivery of physical products!',
                SUBSCRIPTION_YOUR_DOMAIN: 'Your domain is: ',
                CHECKOUT_FIRMNAME: 'Firm name',
                CHECKOUT_PRODUCTS_TOTAL: 'Total',
                SUBSCRIPTION_ORGANISATION_NAME: 'Organisation name',
                CHECKOUT_LNAME_REQUIRED: 'Last name is required!',
                NOT_AVAILABLE: 'Domain is not available',
                SHOP_BACK: 'Back',
                CHECKOUT_ORDER_TITLE: 'Order',
                DOMAIN_TRANSFER: 'Transfer your domain',
                DOMAIN_TRANSFER_BTN: 'Transfer',
                DOMAIN_TRANSFER_HOSTING: 'Transfer with hosting',
                COMING_SOON: 'Coming soon',
                SIGNUP: 'Create your account',
                CONFIRM_PASSWORD: 'Confirm Password',
                AGREE: 'I agree with the',
                REGISTER: 'Register',
                VERIFYING: 'Verifying your account',
                VERIFIED: 'Your account was verified successfully',
                FORGOT: 'Forgot password',
                SEND_EMAIL: 'Send Email',
                USER_HINT: 'Password must be at least 10 characters long and cointain special characters and digits!',
                USER_ERROR: 'Please enter new password!',
                USER_REQUIEREMENTS: 'Password requirements',
                USER_GUIDE: 'Please follow this guide for a strong password',
                USER_CHAR: 'One special character',
                USER_MIN: 'Min 6 characters',
                USER_NUMBER: 'One number(2 are recomended)',
                USER_CHANGE: 'Change if often',
                USER_CHANGEPASSWORD: 'Change Password',
                USER_NEWPASSWORD: 'New password',
                USER_CONFIRM: 'Confirm new password',
                USER_SAVE: 'Save',
                CHECKOUT_BILLING_INFORMATION: 'Billing Information',
                CHECKOUT_FIRMADDRESS: 'Firm address',
                VERIFIED_SUCCESS: 'Your account was verified successfully',
                VERIFIED_ERROR: "There is an error with your email's verification, please try again",
                REGISTER_SUCCESS: 'Your account was registered successfully',
                REGISTER_ERROR: 'Your account was not registered, please try again',
                FORGOT_SUCCESS: 'Your request to change your password was successful',
                FORGOT_ERROR: 'Your request to change your password failed',
                CHANGE_SUCCESS: 'Your password was changed successfully',
                CHANGE_ERROR: 'Your password was not changed, please try again',
                EMAIL: 'Email',
                PASSWORD: 'Password',
                TERMSOFSERVICES: 'Terms and conditions',
                READ_MORE: 'Read more',
                CARD_NUMBER: 'Card number',
                EXPIRY: 'Expiry',
                INSERT_TEXT: 'Insert you text here',
                TEXT: 'Text',
                WAREHOUSE_FILTERS: 'Filters',
                WAREHOUSE_SHOWING: 'Showing',
                WAREHOUSE_FROM: 'From',
                WAREHOUSE_TO: 'To',
                WAREHOUSE_CART: 'Cart',
                RECENT_POSTS: 'Recent posts:',
                PREVIOUS: 'previous',
                NEXT: 'next',
                MORE_ARTICLES: 'More articles',
                JANUARY: 'jan.',
                FEBRUARY: 'feb.',
                MARCH: 'mar.',
                APRIL: 'apr.',
                MAY: 'may',
                JUNE: 'june',
                JULY: 'july',
                AUGUST: 'aug.',
                SEPTEMBER: 'sep.',
                OCTOBER: 'oct.',
                NOVEMBER: 'nov.',
                DECEMBER: 'dec.',
                UNAVAILABLE_QUANTITY: 'Insufficient stock. Only %d left in stock.',
                PRODUCTS: 'Products',
                CATEGORIES: 'Categories',
                FILTERS: 'Filters',
                SEEMORE: 'See more',
                PAYMENT_METHOD_STRIPE: 'Card',
                PAYMENT_METHOD_UPON_DELIVERY: 'Upon Delivery',
            },
            bg: {
                'DOMAIN FORMAT': 'Невалиден формат на домейн',
                'DOMAIN NAME TABLE': 'ИМЕ НА ДОМЕЙН',
                'DOMAIN BUY TABLE': 'КУПИ ДОМЕЙН',
                CHECKOUT_FIRM_ADDRESS_REQUIRED: 'Адрес на фирма е задължително!',
                FOOTER_RETURNS: 'Връщане',
                CHECKOUT_AGRREE_TERMS: 'Моля приемете правилата и условията!',
                SHOP_CATEGORIES: 'Категории',
                CHECKOUT_ADDRESS: 'Адрес',
                CHECKOUT_COUNTRY: 'Държава',
                SHOP_NO_PRODUCTS: 'Няма намерени продукти!',
                TEXT_LOADING: 'Ще отнеме няколко момента да открием вашия домейн...',
                SHOP_PRICE: 'Цена',
                CHECKOUT_SIZE: 'Размер',
                SUBSCRIPTION_DOMAIN_PRICE: 'Цена за домейн',
                TRANSFER_KEY_REQUIRED: 'Ключ за трансфер е задължителен!',
                INFO: 'Ако се абонирате за хостинг с нас получавате домейна безплатно!',
                CHECKOUT_PAYMENT: 'Плащане',
                CHECKOUT_WITH: "със",
                CHECKOUT_TITLE: 'Поръчай',
                CHECKOUT_CORRECT_DATA: 'Моля въведете валидни данни!',
                CHECKOUT_ERROR: 'Грешка',
                DOMAIN_BUY_TABLE: 'КУПИ ДОМЕЙН',
                SUBSCRIPTION_CONFIRM: 'Потвърди',
                SUBSCRIPTION_DOMAIN_INFO: 'При трансфер домейна се удължава с една година',
                CHECKOUTLNAME: 'Фамилия',
                CHECKOUT_CREDIT_CARD: 'Кредитна карта',
                ENTER_DOMAIN: 'Моля въведете име на домейн',
                CHECKOUT_FINISHING_PAYMENT: 'Завършване на плащането',
                SHOP_DONATE: 'Моля въведете сумата, която искате да платите!',
                CHECKOUT_INVOICE_INFO: 'Информация за фактура',
                SHOP_ALL: 'Всички',
                DOMAIN_HOSTING_TABLE: 'КУПИ С ХОСТИНГ',
                DOMAIN_FIND_PERFECT: 'Намерете перфектното име на вашият домейн',
                SHOP_SUCCESS: 'Успешно добавено в количката!',
                SUBSCRIPTION_DOMAIN_TYPE: 'Прехвърляне на домейн',
                SHOP_VALID_PRICE: 'Моля въведете валидна цена!',
                SHOP_CATEGORY: 'Категория',
                CHECKOUT_EMAIL_REQUIRED: 'Имейл е задължително',
                SHOP_FROM: 'От',
                SUBSCRIPTION_HAVE_DOMAIN: 'Прехвърляне на притежаван домейн',
                SEARCH: 'Търси',
                AUTOCHARGE_CONFIRM: 'Потвърди',
                SHOP_ADDTOBAG: 'Добави в количка',
                CHECKOUT_FIRM_ADDRESS: 'Адрес на фирма',
                CHECKOUTLNAME_REQUIRED: 'Фамилия е задължително',
                CHECKOUT_PHONE: 'Телефон',
                SUBSCRIPTION_KEY: 'Ключ за трансфер',
                CHECKOUT__ADDRESS_VALID: 'Моля въведете адрес!',
                SUBSCRIPTION_DOMAIN_NAME: 'Име на домейн',
                CHECKOUT_SHIPPING_INFORMATION: 'Информация за доставка',
                CHECKOUT_ORDER_SUMMARY: 'Обобщение на поръчката',
                CHECKOUT_FNAME: 'Име',
                CHECKOUT_LNAME_REQUIRED: 'Фамилия е задължително',
                CHECKOUT_AGREE: 'Приемам',
                AVAILABLE_DOMAIN: 'Домейнът е свободен',
                CHECKOUT_CREATING_PAYMENT: 'Създаване на плащане',
                CHECKOUT_UIC_REQUIRED: 'ЕИК/UBI е задължително',
                DOMAIN_FREE: 'Безплатно',
                CHECKOUT_CLOSE_INVOICE: 'Затворете ако не желаете фактура!',
                DOMAIN_FORMAT: 'Невалиден формат на домейн',
                SUBSCRIPTION_BACK: 'Назад',
                SHOP_DESCRIPTION: 'Описание',
                CHECKOUT_VAT_REQUIRED: 'ДДС номер е задължително',
                CHECKOUT_MINIMUM_PRICE: 'Поръчката не е достигнала минималната сума!',
                CHECKOUT_FNAME_REQUIRED: 'Име е задължително',
                CHECKOUT_EMAIL_VALID: 'Моля въведете валиден имейл адрес',
                CHECKOUT_PROCESSING_PAYMENT: 'Плащането се обработва',
                FOOTER_TERMS: 'Условия',
                SUBSCRIPTION_NEXT: 'Напред',
                SHOPPING_CART_BTN: 'Поръчай',
                CHECKOUT_PHONE_REQUIRED: 'Телефон е задължително',
                CHECKOUT_CARD_TITLE: 'Карта',
                CHECKOUT_ADDRESS_REQUIRED: 'Адрес е задължително!',
                CHECKOUT_COUNTRY_REQUIRED: 'Moля изберете държава!',
                CHECKOUT_UIC: 'ЕИК/UBI',
                CHECKOUT_SUBSCRIBE: "Абонирай ме за новини",
                SHOPPING_CART_TITLE: 'Количка',
                CHECKOUT_PAYED: 'Успешно платена поръчка',
                CHECKOUT_FIRMNAME_REQUIRED: 'Име на фирма е задължително',
                CHECKOUT_FIRM_ADDRESS_VALID: 'Моля въведете адрес на фирма!',
                SHOP_SIZE: 'Размер',
                AUTOCHARGE_TITLE3DS: 'Необходима е 3D парола за да завършим плащането',
                CHECKOUT_PHONE_VALID: 'Моля въведете валиден телефон',
                CHECKOUT_TERMS: 'Правилата и условията',
                AUTOCHARGE_TITLENEW: 'Моля въведете карта с която искате да заплатите',
                CHECKOUT_PRODUCTS_TITLE: 'Продукти',
                SUCCESS: 'Успешно!',
                CHECKOUT_EMAIL: 'Имейл',
                CHECKOUT_DELIVERY: '*В цената не е включена доставка на физически продукти!',
                DOMAIN_NAME_TABLE: 'ИМЕ НА ДОМЕЙН',
                CHECKOUT_PAY_BTN: 'Потвърди поръчка',
                CHECKOUT_CONTACT_INFORMATION: 'Информация за контакт',
                FOOTER_DELIVERY: 'Доставка',
                SUBSCRIPTION_DOMAIN: 'Нов домейн',
                AVAILABLE: 'Зареждане на свободни',
                MORE: 'Зареди още',
                CHECKOUT_VAT: 'ДДС номер',
                SHOPPING_CART_EMPTY: 'Количката е празна!',
                FOOTER_CREATED: 'Създадено от Социален Облак',
                CHECKOUT_DESCRIPTION: 'Допълнително описание',
                SUBSCRIPTION_YOUR_DOMAIN: 'Вашият домейн е: ',
                CHECKOUT_FIRMNAME: 'Име на фирма',
                CHECKOUT_PRODUCTS_TOTAL: 'Тотал',
                NOT_AVAILABLE: 'Домейнът не е свободен',
                SHOP_BACK: 'Назад',
                CHECKOUT_LNAME: 'Фамилия',
                CHECKOUT_ORDER_TITLE: 'Поръчка',
                DOMAIN_TRANSFER: 'Трансферирай своя домейн',
                DOMAIN_TRANSFER_BTN: 'Трансферирай',
                DOMAIN_TRANSFER_HOSTING: 'Трансферирай с хостинг',
                COMING_SOON: 'Очаквайте скоро',
                SIGNUP: 'Създайте своя акаунт',
                CONFIRM_PASSWORD: 'Потвърдете паролата',
                AGREE: 'Съгласен съм с',
                REGISTER: 'Регистрация',
                VERIFYING: 'Потвърждаване на вашия акаунт',
                VERIFIED: 'Вашият акаунт беше потвърден успешно',
                FORGOT: 'Забравена парола',
                SEND_EMAIL: 'Изпращане на имейл',
                USER_HINT: 'Паролата трябва да е с дължина поне 10 знака и да съдържа специални символи и цифри!',
                USER_ERROR: 'Моля въведете нова парола!',
                USER_REQUIEREMENTS: 'Изисквания за парола',
                USER_GUIDE: 'Моля, следвайте това ръководство за силна парола',
                USER_CHAR: 'Един специален знак',
                USER_MIN: 'Минимум 6 знака',
                USER_NUMBER: 'Еднo число (препоръчват се 2)',
                USER_CHANGE: 'Променяйте я често',
                USER_CHANGEPASSWORD: 'Смяна на паролата',
                USER_NEWPASSWORD: 'Нова парола',
                USER_CONFIRM: 'Потвърди новата парола',
                USER_SAVE: 'Запиши',
                CHECKOUT_BILLING_INFORMATION: 'Информация за фактура',
                CHECKOUT_FIRMADDRESS: 'Адрес на фирма',
                VERIFIED_SUCCESS: 'Акаунтът ви бе потвърден успешно',
                VERIFIED_ERROR: 'Има грешка при потвърждението на вашия имейл, моля, опитайте отново',
                REGISTER_SUCCESS: 'Вашият акаунт беше регистриран успешно',
                REGISTER_ERROR: 'Вашият акаунт не е регистриран, моля, опитайте отново',
                FORGOT_SUCCESS: 'Заявката ви за промяна на паролата ви беше успешна',
                FORGOT_ERROR: 'Вашата заявка за промяна на паролата ви е неуспешна',
                CHANGE_SUCCESS: 'Паролата ви беше променена успешно',
                CHANGE_ERROR: 'Паролата ви не е променена, моля, опитайте отново',
                EMAIL: 'Имейл',
                PASSWORD: 'Парола',
                TERMSOFSERVICES: 'Правилата и условията',
                READ_MORE: 'Прочети още',
                CARD_NUMBER: 'Карта номер',
                EXPIRY: 'Изтича',
                INSERT_TEXT: 'Въведете текст тук',
                TEXT: 'Текст',
                WAREHOUSE_FILTERS: 'Филтри',
                WAREHOUSE_SHOWING: 'Показани',
                WAREHOUSE_FROM: 'От',
                WAREHOUSE_TO: 'До',
                WAREHOUSE_CART: 'Количка',
                RECENT_POSTS: 'Последни публикации:',
                PREVIOUS: 'предишен',
                NEXT: 'следващ',
                MORE_ARTICLES: 'Още публикации',
                JANUARY: 'яну.',
                FEBRUARY: 'фев.',
                MARCH: 'март',
                APRIL: 'апр.',
                MAY: 'май',
                JUNE: 'юни',
                JULY: 'юли',
                AUGUST: 'авг.',
                SEPTEMBER: 'сеп.',
                OCTOBER: 'окт.',
                NOVEMBER: 'нов.',
                DECEMBER: 'дек.',
                UNAVAILABLE_QUANTITY: 'Недостатъчна наличност. Остават %d налични.',
                PRODUCTS: 'Продукти',
                CATEGORIES: 'Категории',
                FILTERS: 'Филтри',
                SEEMORE: 'Виж повече',
                PAYMENT_METHOD_STRIPE: 'Карта',
                PAYMENT_METHOD_UPON_DELIVERY: 'Наложен платеж',
            },
        };
    }
    TranslateService.prototype.loadTranslations = function (lang) {
        if (typeof translations !== 'undefined') {
            this.translations = __assign(__assign(__assign({}, translations[lang]), this.privateTranslations[lang]), this.publicTranslations[lang]);
            this.currentLang.next(lang);
            return new BehaviorSubject(this.translations).asObservable();
        }
        else {
            console.warn("Translations for '".concat(lang, "' not found."));
            this.translations = __assign(__assign({}, this.privateTranslations[lang]), this.publicTranslations[lang]);
            return new BehaviorSubject(__assign(__assign({}, this.privateTranslations[lang]), this.publicTranslations[lang])).asObservable();
        }
    };
    TranslateService.prototype.translate = function (key) {
        return this.translations[key] || key;
    };
    TranslateService.prototype.getCurrentLang = function () {
        return this.currentLang.asObservable();
    };
    TranslateService.prototype.changeLanguage = function (lang) {
        this.loadTranslations(lang).subscribe();
    };
    TranslateService.prototype.add = function (translation, main) {
        this.translations = __assign(__assign({}, this.translations), translation);
        if (main) {
            this.mainTranslations[this.settingsSvc.getLanguage()] = __assign({}, this.translations);
        }
    };
    TranslateService.prototype.set = function (data) {
        typeof translations !== 'undefined' ? (translations = data) : {};
        this.mainTranslations = data;
        this.translations = __assign(__assign({}, this.translations), data[this.settingsSvc.getLanguage()]);
    };
    TranslateService.prototype.emit = function (data, main) {
        var event = new CustomEvent('translations:add', {
            detail: { translations: data, main: main },
        });
        window.dispatchEvent(event);
    };
    TranslateService.prototype.interpolate = function (translation, params) {
        var index = 0;
        return translation.replace(/%[sd]/g, function (match) {
            var param = params[index++];
            return param !== undefined ? param : match;
        });
    };
    TranslateService.prototype.get = function (key) {
        var _this = this;
        var params = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            params[_i - 1] = arguments[_i];
        }
        return this.currentLang.pipe(switchMap(function () {
            // Logic to return the translation for the key
            var translation = _this.translations[key] || key;
            if (params.length) {
                translation = _this.interpolate(translation, params);
            }
            return of(translation);
        }));
    };
    TranslateService.prototype.instant = function (key) {
        return this.translations[key] || key;
    };
    TranslateService.prototype.getLangs = function () {
        if (typeof languages !== 'undefined') {
            this.availableLangs = languages;
        }
        return this.availableLangs;
    };
    Object.defineProperty(TranslateService.prototype, "getMain", {
        get: function () {
            return this.mainTranslations;
        },
        enumerable: false,
        configurable: true
    });
    TranslateService.ɵfac = function TranslateService_Factory(t) { return new (t || TranslateService)(i0.ɵɵinject(i1.SettingsService)); };
    TranslateService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TranslateService, factory: TranslateService.ɵfac, providedIn: 'root' });
    return TranslateService;
}());
export { TranslateService };
