import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var TokenStateService = /** @class */ (function () {
    function TokenStateService() {
        this.currentTokenSource = new BehaviorSubject('');
        this.currentToken$ = this.currentTokenSource.asObservable();
    }
    TokenStateService.prototype.setToken = function (token) {
        this.currentTokenSource.next(token);
    };
    TokenStateService.ɵfac = function TokenStateService_Factory(t) { return new (t || TokenStateService)(); };
    TokenStateService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TokenStateService, factory: TokenStateService.ɵfac, providedIn: 'root' });
    return TokenStateService;
}());
export { TokenStateService };
