import { __decorate } from "tslib";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as i0 from "@angular/core";
import * as i1 from "./token-state.service";
import * as i2 from "./infrastructure.service";
var StateService = /** @class */ (function () {
    function StateService(tokenStateSvc, infrastructureSvc) {
        var _this = this;
        this.tokenStateSvc = tokenStateSvc;
        this.infrastructureSvc = infrastructureSvc;
        this.tokenStateSvc.currentToken$
            .pipe(untilDestroyed(this))
            .subscribe(function (token) {
            _this.token = token;
            var event = new CustomEvent('setToken', { detail: token });
            window.dispatchEvent(event);
        });
        this.infrastructureSvc.resourcesGroup$
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            _this.groups = groups;
            var event = new CustomEvent('setGroups', { detail: groups });
            window.dispatchEvent(event);
        });
        this.infrastructureSvc.currentResourceGroup$
            .pipe(untilDestroyed(this))
            .subscribe(function (group) {
            _this.currentResourceGroup = group;
        });
        this.infrastructureSvc.directoriesBelong$
            .pipe(untilDestroyed(this))
            .subscribe(function (directories) {
            _this.directories = directories;
        });
        this.infrastructureSvc.currentResourceDirectory$
            .pipe(untilDestroyed(this))
            .subscribe(function (directory) {
            _this.currentResourceDirectory = directory;
        });
        this.infrastructureSvc.currentTemplate$
            .pipe(untilDestroyed(this))
            .subscribe(function (template) {
            _this.currentTemplate = template;
        });
    }
    StateService.prototype.setToken = function () {
        var event = new CustomEvent('setToken', { detail: this.token });
        window.dispatchEvent(event);
    };
    StateService.prototype.setGroups = function () {
        var event = new CustomEvent('setGroups', { detail: this.groups });
        window.dispatchEvent(event);
    };
    StateService.prototype.setCurrentGroup = function () {
        var event = new CustomEvent('setCurrentGroup', {
            detail: this.currentResourceGroup,
        });
        window.dispatchEvent(event);
    };
    StateService.prototype.setDirectories = function () {
        var event = new CustomEvent('setDirectories', {
            detail: this.directories,
        });
        window.dispatchEvent(event);
    };
    StateService.prototype.setCurrentDirectory = function () {
        var event = new CustomEvent('setCurrentDirectory', {
            detail: this.currentResourceDirectory,
        });
        window.dispatchEvent(event);
    };
    StateService.prototype.setTemplate = function () {
        var event = new CustomEvent('setTemplate', {
            detail: this.currentTemplate,
        });
        window.dispatchEvent(event);
    };
    StateService.prototype.setInitialValues = function () {
        this.setToken();
        this.setGroups();
        this.setCurrentGroup();
        this.setDirectories();
        this.setCurrentDirectory();
        this.setTemplate();
    };
    StateService.ɵfac = function StateService_Factory(t) { return new (t || StateService)(i0.ɵɵinject(i1.TokenStateService), i0.ɵɵinject(i2.InfrastructureService)); };
    StateService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: StateService, factory: StateService.ɵfac, providedIn: 'root' });
    StateService = __decorate([
        UntilDestroy()
    ], StateService);
    return StateService;
}());
export { StateService };
