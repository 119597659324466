import { v4 as uuidv4 } from 'uuid';
import { NotificationComponent } from '../components/notification/notification.component';
import * as i0 from "@angular/core";
import * as i1 from "./settings.service";
var NotificationService = /** @class */ (function () {
    function NotificationService(componentFactoryResolver, appRef, injector, settingsSvc) {
        this.componentFactoryResolver = componentFactoryResolver;
        this.appRef = appRef;
        this.injector = injector;
        this.settingsSvc = settingsSvc;
        this.notifications = [];
        this.headings = {
            en: {
                info: 'Information',
                success: 'Success',
                warning: 'Warning',
                error: 'Error',
            },
            bg: {
                info: 'Информация',
                success: 'Успешно',
                warning: 'Внимание',
                error: 'Грешка',
            },
        };
        this.language = this.settingsSvc.getLanguage();
    }
    NotificationService.prototype.showNotification = function (description, type) {
        this.createNotification(description, type);
        if (!this.notificationComponentRef) {
            var factory = this.componentFactoryResolver.resolveComponentFactory(NotificationComponent);
            // Create a component reference
            this.notificationComponentRef = factory.create(this.injector);
            this.notificationComponentRef.instance.notifications = this.notifications;
            // Attach the component to the application's root view
            this.appRef.attachView(this.notificationComponentRef.hostView);
            // Append the component's DOM element to the body
            document.body.appendChild(this.notificationComponentRef.location.nativeElement);
        }
        else {
            this.notificationComponentRef.instance.notifications = this.notifications;
        }
    };
    NotificationService.prototype.createNotification = function (description, type) {
        var _this = this;
        var id = uuidv4();
        var newNotification = {
            id: id,
            title: this.headings[this.language][type],
            description: description,
            type: type,
        };
        this.notifications.push(newNotification);
        setTimeout(function () {
            _this.closeNotificaiton(id);
        }, 10000);
    };
    NotificationService.prototype.closeNotificaiton = function (id) {
        var _this = this;
        this.notifications = this.notifications.filter(function (el) { return el.id !== id; });
        if (this.notificationComponentRef) {
            this.notificationComponentRef.instance.notifications = this.notifications;
        }
        if (this.notificationComponentRef && this.notifications.length <= 0) {
            setTimeout(function () {
                // Remove the component from the DOM and destroy it
                _this.appRef.detachView(_this.notificationComponentRef.hostView);
                _this.notificationComponentRef.destroy();
                _this.notificationComponentRef = undefined;
            }, 300);
        }
    };
    NotificationService.prototype.emitNotification = function (description, type) {
        var event = new CustomEvent('showNotification', {
            detail: { description: description, type: type },
        });
        window.dispatchEvent(event);
    };
    NotificationService.ɵfac = function NotificationService_Factory(t) { return new (t || NotificationService)(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.SettingsService)); };
    NotificationService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: NotificationService, factory: NotificationService.ɵfac, providedIn: 'root' });
    return NotificationService;
}());
export { NotificationService };
