import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
var SafeUrlPipe = /** @class */ (function () {
    function SafeUrlPipe(sanitizer) {
        this.sanitizer = sanitizer;
    }
    SafeUrlPipe.prototype.transform = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    SafeUrlPipe.ɵfac = function SafeUrlPipe_Factory(t) { return new (t || SafeUrlPipe)(i0.ɵɵdirectiveInject(i1.DomSanitizer, 16)); };
    SafeUrlPipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "safeResourceUrl", type: SafeUrlPipe, pure: true });
    return SafeUrlPipe;
}());
export { SafeUrlPipe };
