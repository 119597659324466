import * as i0 from "@angular/core";
var SettingsService = /** @class */ (function () {
    function SettingsService() {
        this.currencyKey = 'currency';
        this.languageKey = 'language';
        this.setDefaultCurrencyAndLanguage();
        window.addEventListener('changeLanguage', this.handleLanguageChange.bind(this));
        window.addEventListener('changeCurrency', this.handleCurrencyChange.bind(this));
    }
    SettingsService.prototype.setDefaultCurrencyAndLanguage = function () {
        var availableCurrencies = window.currencies || [];
        var availableLanguages = window.languages || [];
        var defaultCurrency = availableCurrencies[0];
        var defaultLanguage = availableLanguages[0];
        // Get the browser's default language (e.g., 'en', 'bg')
        var browserLanguage = navigator.language.slice(0, 2); // Get first two letters, e.g., 'en'
        // If browser language exists in available languages, use it as default
        var browserDefaultLanguage = availableLanguages.includes(browserLanguage)
            ? browserLanguage
            : defaultLanguage;
        // If no value in localStorage, set to default
        if (!this.getCurrency()) {
            this.setCurrency(defaultCurrency || 'BGN');
        }
        if (!this.getLanguage()) {
            this.setLanguage(browserDefaultLanguage || 'bg');
        }
    };
    SettingsService.prototype.languageExist = function (language) {
        var _a;
        var availableLanguages = window.languages || ((_a = window === null || window === void 0 ? void 0 : window.parent) === null || _a === void 0 ? void 0 : _a.languages) || [];
        if (availableLanguages.includes(language))
            return true;
        else
            return false;
    };
    SettingsService.prototype.currencyExist = function (currency) {
        var _a;
        var availableCurrencies = window.currencies || ((_a = window === null || window === void 0 ? void 0 : window.parent) === null || _a === void 0 ? void 0 : _a.currencies) || [];
        [];
        if (availableCurrencies.includes(currency))
            return true;
        else
            return false;
    };
    SettingsService.prototype.setCurrency = function (currency) {
        if (!this.currencyExist)
            return;
        localStorage.setItem(this.currencyKey, currency);
        var event = new CustomEvent('changeCurrency', {
            detail: currency,
        });
        window.dispatchEvent(event);
    };
    SettingsService.prototype.getCurrency = function () {
        var storageCurrency = localStorage.getItem(this.currencyKey);
        if (!this.currencyExist) {
            var availableCurrencies = window.currencies || [];
            var defaultCurrency = availableCurrencies[0] || 'BGN';
            localStorage.setItem(this.currencyKey, defaultCurrency);
            return defaultCurrency;
        }
        else {
            return storageCurrency;
        }
    };
    SettingsService.prototype.setLanguage = function (language) {
        if (!this.languageExist(language))
            return;
        localStorage.setItem(this.languageKey, language);
        var event = new CustomEvent('changeLanguage', {
            detail: language,
        });
        window.dispatchEvent(event);
    };
    SettingsService.prototype.getLanguage = function () {
        var storageLanguage = localStorage.getItem(this.languageKey);
        if (!this.languageExist(storageLanguage)) {
            var availableLanguages = window.languages || [];
            var defaultLanguage = availableLanguages[0] || 'bg';
            localStorage.setItem(this.languageKey, defaultLanguage);
            return defaultLanguage;
        }
        else {
            return storageLanguage;
        }
    };
    SettingsService.prototype.handleLanguageChange = function (data) {
        if (this.getLanguage() !== data.detail) {
            this.setLanguage(data.detail);
        }
    };
    SettingsService.prototype.handleCurrencyChange = function (data) {
        if (this.getCurrency() !== data.detail) {
            this.setCurrency(data.detail);
        }
    };
    SettingsService.prototype.ngOnDestroy = function () {
        window.removeEventListener('changeLanguage', this.handleLanguageChange.bind(this));
        window.removeEventListener('changeCurrency', this.handleCurrencyChange.bind(this));
    };
    SettingsService.ɵfac = function SettingsService_Factory(t) { return new (t || SettingsService)(); };
    SettingsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: SettingsService, factory: SettingsService.ɵfac, providedIn: 'root' });
    return SettingsService;
}());
export { SettingsService };
