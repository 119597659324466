import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "src/app/shared/services/path.service";
var InfrastructureService = /** @class */ (function () {
    function InfrastructureService(http, pathSvc) {
        var _this = this;
        var _a, _b, _c, _d;
        this.http = http;
        this.pathSvc = pathSvc;
        this.resourceUrl = '/api/resource';
        this.port = environment.port;
        this.currentResourceDirectorySource = new BehaviorSubject({
            domain: environment.domain,
            id: typeof properties !== 'undefined'
                ? properties === null || properties === void 0 ? void 0 : properties.directoryId
                : (_a = window.properties) === null || _a === void 0 ? void 0 : _a.directoryId,
        });
        this.currentResourceDirectory$ = this.currentResourceDirectorySource.asObservable();
        this.currentTemplateSource = new BehaviorSubject(typeof properties !== 'undefined'
            ? { id: properties === null || properties === void 0 ? void 0 : properties.templateId }
            : {
                id: ((_b = window.properties) === null || _b === void 0 ? void 0 : _b.templateId) ||
                    localStorage.getItem('currentTemplateId'),
            });
        this.currentTemplate$ = this.currentTemplateSource.asObservable();
        this.directoriesBelongSource = new BehaviorSubject([]);
        this.directoriesBelong$ = this.directoriesBelongSource.asObservable();
        this.currentResourceGroupSource = new BehaviorSubject({
            groupName: window.location.hostname,
            id: typeof properties !== 'undefined'
                ? properties === null || properties === void 0 ? void 0 : properties.groupId
                : (_c = window.properties) === null || _c === void 0 ? void 0 : _c.groupId,
            rights: typeof properties !== 'undefined'
                ? properties === null || properties === void 0 ? void 0 : properties.rights
                : (_d = window.properties) === null || _d === void 0 ? void 0 : _d.rights,
        });
        this.currentResourceGroup$ = this.currentResourceGroupSource.asObservable();
        this.resourceGroupsSource = new BehaviorSubject([]);
        this.resourcesGroup$ = this.resourceGroupsSource.asObservable();
        this.haveValuesSource = new BehaviorSubject(false);
        this.haveValues$ = this.haveValuesSource.asObservable();
        window.addEventListener('resetGroup', function (data) {
            if (data.detail.group) {
                _this.currentResourceGroupSource.next(data.detail.group);
            }
        });
    }
    InfrastructureService.prototype.getGroupsForResourceDirectory = function (directoryId, domain) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(this.resourceUrl, "/getGroupsYouBelongTo?resourceDirectoryId=").concat(directoryId));
    };
    InfrastructureService.prototype.getDirectoryBelongings = function (domain) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(this.resourceUrl, "/allDirectories"));
    };
    InfrastructureService.prototype.setResourceDirectory = function (directory) {
        this.currentResourceDirectorySource.next(directory);
    };
    InfrastructureService.prototype.setResourceGroup = function (resourceGroupId) {
        console.log(resourceGroupId);
        var groups = this.resourceGroupsSource.getValue();
        var currentGroup = groups.find(function (el) { return el.id === resourceGroupId; });
        if (currentGroup) {
            this.currentResourceGroupSource.next(currentGroup);
        }
    };
    InfrastructureService.prototype.setCurrentGroup = function (group) {
        console.log(group, '1');
        this.currentResourceGroupSource.next(group);
    };
    InfrastructureService.prototype.setDirectories = function (directories) {
        this.directoriesBelongSource.next(directories);
    };
    InfrastructureService.prototype.setResourceGroups = function (groups) {
        this.resourceGroupsSource.next(groups);
        var event = new CustomEvent('setGroups', { detail: groups });
        window.dispatchEvent(event);
    };
    InfrastructureService.prototype.setValue = function (value) {
        this.haveValuesSource.next(value);
    };
    InfrastructureService.prototype.setTemplate = function (value) {
        this.currentTemplateSource.next(value);
    };
    InfrastructureService.prototype.updateTemplate = function (value) {
        var template = Object.assign(this.currentTemplateSource.getValue(), value);
        this.currentTemplateSource.next(template);
    };
    InfrastructureService.prototype.clearState = function () {
        this.currentResourceDirectorySource.next(undefined);
        this.currentResourceGroupSource.next(undefined);
        this.directoriesBelongSource.next([]);
        this.resourceGroupsSource.next([]);
        this.haveValuesSource.next(false);
    };
    InfrastructureService.ɵfac = function InfrastructureService_Factory(t) { return new (t || InfrastructureService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PathService)); };
    InfrastructureService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: InfrastructureService, factory: InfrastructureService.ɵfac, providedIn: 'root' });
    return InfrastructureService;
}());
export { InfrastructureService };
