import * as i0 from "@angular/core";
var ThemesService = /** @class */ (function () {
    function ThemesService() {
    }
    ThemesService.prototype.init = function (element) {
        if (localStorage.getItem('color-theme') === 'dark' ||
            (!localStorage.getItem('color-theme') &&
                window.matchMedia('(prefers-color-scheme: dark)').matches)) {
            document.documentElement.classList.add('dark');
            element.nativeElement.classList.add('dark');
            localStorage.setItem('color-theme', 'dark');
        }
        else {
            document.documentElement.classList.remove('dark');
            element.nativeElement.classList.remove('dark');
            localStorage.setItem('color-theme', 'light');
        }
    };
    ThemesService.prototype.change = function (element) {
        if (localStorage.getItem('color-theme')) {
            if (localStorage.getItem('color-theme') === 'light') {
                document.documentElement.classList.add('dark');
                element.nativeElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
                this.emit('dark');
            }
            else {
                document.documentElement.classList.remove('dark');
                element.nativeElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
                this.emit('light');
            }
        }
        else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                element.nativeElement.classList.remove('dark');
                localStorage.setItem('color-theme', 'light');
                this.emit('light');
            }
            else {
                document.documentElement.classList.add('dark');
                element.nativeElement.classList.add('dark');
                localStorage.setItem('color-theme', 'dark');
                this.emit('dark');
            }
        }
    };
    ThemesService.prototype.emit = function (theme) {
        var event = new CustomEvent('changeTheme', { detail: theme });
        window.dispatchEvent(event);
    };
    ThemesService.ɵfac = function ThemesService_Factory(t) { return new (t || ThemesService)(); };
    ThemesService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: ThemesService, factory: ThemesService.ɵfac, providedIn: 'root' });
    return ThemesService;
}());
export { ThemesService };
