import * as i0 from "@angular/core";
import * as i1 from "../services/translate.service";
var TranslatePipe = /** @class */ (function () {
    function TranslatePipe(translateSvc) {
        this.translateSvc = translateSvc;
    }
    TranslatePipe.prototype.transform = function (value) {
        return this.translateSvc.translate(value);
    };
    TranslatePipe.ɵfac = function TranslatePipe_Factory(t) { return new (t || TranslatePipe)(i0.ɵɵdirectiveInject(i1.TranslateService, 16)); };
    TranslatePipe.ɵpipe = /*@__PURE__*/ i0.ɵɵdefinePipe({ name: "translate", type: TranslatePipe, pure: false });
    return TranslatePipe;
}());
export { TranslatePipe };
