import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var ScrollTrackerDirective = /** @class */ (function () {
    function ScrollTrackerDirective(el) {
        this.el = el;
        this.scrolledToBottom = new EventEmitter();
    }
    ScrollTrackerDirective.prototype.onScroll = function (event) {
        var target = event.target;
        var scrollPosition = target.scrollTop + target.clientHeight;
        var scrollHeight = target.scrollHeight;
        if (scrollPosition >= scrollHeight) {
            this.scrolledToBottom.emit();
        }
    };
    ScrollTrackerDirective.ɵfac = function ScrollTrackerDirective_Factory(t) { return new (t || ScrollTrackerDirective)(i0.ɵɵdirectiveInject(i0.ElementRef)); };
    ScrollTrackerDirective.ɵdir = /*@__PURE__*/ i0.ɵɵdefineDirective({ type: ScrollTrackerDirective, selectors: [["", "appScrollTracker", ""]], hostBindings: function ScrollTrackerDirective_HostBindings(rf, ctx) { if (rf & 1) {
            i0.ɵɵlistener("scroll", function ScrollTrackerDirective_scroll_HostBindingHandler($event) { return ctx.onScroll($event); });
        } }, outputs: { scrolledToBottom: "scrolledToBottom" } });
    return ScrollTrackerDirective;
}());
export { ScrollTrackerDirective };
