import { __awaiter, __generator, __read } from "tslib";
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
export var IntersectionStatus;
(function (IntersectionStatus) {
    IntersectionStatus["Visible"] = "Visible";
    IntersectionStatus["Pending"] = "Pending";
    IntersectionStatus["NotVisible"] = "NotVisible";
})(IntersectionStatus || (IntersectionStatus = {}));
export var fromIntersectionObserver = function (element, config, debounce) {
    if (debounce === void 0) { debounce = 0; }
    return new Observable(function (subscriber) {
        var subject$ = new Subject();
        var intersectionObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (isIntersecting(entry)) {
                    subject$.next({ entry: entry, observer: observer });
                }
            });
        }, config);
        subject$.subscribe(function () {
            subscriber.next(IntersectionStatus.Pending);
        });
        subject$
            .pipe(debounceTime(debounce), filter(Boolean))
            .subscribe(function (_a) {
            var entry = _a.entry, observer = _a.observer;
            return __awaiter(void 0, void 0, void 0, function () {
                var isEntryVisible;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, isVisible(entry.target)];
                        case 1:
                            isEntryVisible = _b.sent();
                            if (isEntryVisible) {
                                subscriber.next(IntersectionStatus.Visible);
                                observer.unobserve(entry.target);
                            }
                            else {
                                subscriber.next(IntersectionStatus.NotVisible);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        });
        intersectionObserver.observe(element);
        return {
            unsubscribe: function () {
                intersectionObserver.disconnect();
                subject$.unsubscribe();
            },
        };
    });
};
function isVisible(element) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve) {
                    var observer = new IntersectionObserver(function (_a) {
                        var _b = __read(_a, 1), entry = _b[0];
                        resolve(entry.isIntersecting);
                        observer.disconnect();
                    });
                    observer.observe(element);
                })];
        });
    });
}
function isIntersecting(entry) {
    return entry.isIntersecting || entry.intersectionRatio > 0;
}
