import { environment } from '../../../../environments/environment';
import { map } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../app/shared/services/path.service";
var BuilderService = /** @class */ (function () {
    function BuilderService(http, pathSvc) {
        this.http = http;
        this.pathSvc = pathSvc;
        this.builderUrl = environment.builderPathname;
        this.port = environment.port;
    }
    BuilderService.prototype.addTranslation = function (domain, translation) {
        return this.http.post("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(this.builderUrl, "/translations"), {
            content: translation.content,
            language: translation.language,
            groupId: translation.groupId,
            templateId: translation.templateId,
        });
    };
    BuilderService.prototype.translate = function (domain, sourceLang, translateLangs, groupId, templateId) {
        var model = {
            sourceLang: sourceLang,
            translateLangs: translateLangs,
            groupId: groupId,
            templateId: templateId,
        };
        return this.http.post("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(this.builderUrl, "/translations/translate"), model);
    };
    BuilderService.prototype.getTranslations = function (lang, currentGroup, currentTemplate) {
        var domain = currentGroup.groupName;
        var groupId = currentGroup.id;
        var templateId = currentTemplate;
        return this.http
            .get("".concat(this.pathSvc.setPath(domain, this.port, true), "/global/v1/builder?language=").concat(lang, "&groupId=").concat(groupId, "&templateId=").concat(templateId))
            .pipe(map(function (translations) {
            translations = translations.content.reduce(function (acc, field) {
                acc[field.id] = field.text;
                return acc;
            }, {});
            return translations;
        }));
    };
    BuilderService.prototype.listTranslations = function (currentGroup, currentTemplate) {
        var domain = currentGroup.groupName;
        var groupId = currentGroup.id;
        var templateId = currentTemplate;
        return this.http
            .get("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(this.builderUrl, "/translations/list?groupId=").concat(groupId, "&templateId=").concat(templateId))
            .pipe(map(function (translations) {
            var result = {};
            var keys = Object.keys(translations.languages);
            keys.forEach(function (el) {
                result[el] = {};
                result[el] = translations.languages[el].content.reduce(function (acc, field) {
                    acc[field.id] = field.text;
                    return acc;
                }, {});
            });
            return result;
        }));
    };
    BuilderService.prototype.clone = function (domain, template) {
        return this.http.post("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(environment.builderPathname, "/templates/clone/").concat(template.id), template);
    };
    BuilderService.prototype.getFonts = function () {
        return this.http.get("https://www.googleapis.com/webfonts/v1/webfonts?key=".concat(environment.fontsApiKey));
    };
    BuilderService.prototype.createComponent = function (domain, component) {
        return this.http.post("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(environment.builderPathname, "/components/").concat(component.id), component);
    };
    BuilderService.prototype.listComponents = function (domain, resourceGroupId) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, false)).concat(environment.builderPathname, "/components/").concat(resourceGroupId));
    };
    BuilderService.ɵfac = function BuilderService_Factory(t) { return new (t || BuilderService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PathService)); };
    BuilderService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: BuilderService, factory: BuilderService.ɵfac, providedIn: 'root' });
    return BuilderService;
}());
export { BuilderService };
