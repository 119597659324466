import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { createCustomElement } from '@angular/elements';
import { BuilderComponent } from './components/builder/builder.component';
import { GroupsComponent } from './components/groups/groups.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UserComponent } from './components/user/user.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { HttpTokenInterceptor } from '../shared/interceptors/http-token.interceptor';
import { LazyWrapperComponent } from './components/lazy-wrapper/lazy-wrapper.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { CoreModule } from '../shared/core/core.module';
import { CodeEditorModule } from '@ngstack/code-editor';
import { InfoBlogComponent } from './components/info-blog/info-blog.component';
import { SelectPostComponent } from './components/select-post/select-post.component';
import { DndModule } from 'ngx-drag-drop';
import { DropdownComponent } from '../shared/components/dropdown/dropdown.component';
import { BlogTabsComponent } from './components/blog-tabs/blog-tabs.component';
import { PropsDialogComponent } from './components/props-dialog/props-dialog.component';
import { CreateComponentDialogComponent } from './create-component-dialog/create-component-dialog.component';
import { BuilderUploadComponent } from './components/builder-upload/builder-upload.component';
import { LoginComponent } from '../public/utils/components/login/login.component';
import { PagesComponent } from './components/pages/pages.component';
import { PagesSettingsComponent } from './components/pages-settings/pages-settings.component';
import { CodeEditorComponent } from './components/code-editor/code-editor.component';
import { TemplatesComponent } from './components/templates/templates.component';
import { TemplatesSettingsComponent } from './components/templates-settings/templates-settings.component';
import { SidenavComponent } from '../public/sidenav/components/sidenav/sidenav.component';
import { FilesComponent } from './files/files.component';
import * as i0 from "@angular/core";
import * as i1 from "../shared/services/translate.service";
import * as i2 from "../shared/services/settings.service";
import * as i3 from "@ngstack/code-editor";
var PrivateModule = /** @class */ (function () {
    function PrivateModule(injector, translate, settingsSvc) {
        var _this = this;
        this.injector = injector;
        this.translate = translate;
        this.settingsSvc = settingsSvc;
        window.addEventListener('changeLanguage', function (data) {
            _this.translate.changeLanguage(data.detail);
        });
        window.addEventListener('translations:add', function (data) {
            _this.translate.add(data.detail.translations, data.detail.main);
        });
        this.translate.loadTranslations(this.settingsSvc.getLanguage());
    }
    PrivateModule.prototype.ngDoBootstrap = function () {
        var builder = createCustomElement(BuilderComponent, {
            injector: this.injector,
        });
        var sidenav = createCustomElement(SidenavComponent, {
            injector: this.injector,
        });
        var groups = createCustomElement(GroupsComponent, {
            injector: this.injector,
        });
        var settings = createCustomElement(SettingsComponent, {
            injector: this.injector,
        });
        var warehouse = createCustomElement(WarehouseComponent, {
            injector: this.injector,
        });
        var user = createCustomElement(UserComponent, {
            injector: this.injector,
        });
        var fileUploader = createCustomElement(FileUploadComponent, {
            injector: this.injector,
        });
        var login = createCustomElement(LoginComponent, {
            injector: this.injector,
        });
        var infoBlog = createCustomElement(InfoBlogComponent, {
            injector: this.injector,
        });
        var selectPost = createCustomElement(SelectPostComponent, {
            injector: this.injector,
        });
        var lazyElement = createCustomElement(LazyWrapperComponent, {
            injector: this.injector,
        });
        var files = createCustomElement(FilesComponent, {
            injector: this.injector,
        });
        customElements.get('sc-builder') ||
            customElements.define('sc-builder', builder);
        customElements.get('sc-warehouse-wrap') ||
            customElements.define('sc-warehouse-wrap', warehouse);
        customElements.get('sc-user-wrap') ||
            customElements.define('sc-user-wrap', user);
        customElements.get('sc-settings-wrap') ||
            customElements.define('sc-settings-wrap', settings);
        customElements.get('sc-groups') ||
            customElements.define('sc-groups', groups);
        customElements.get('sc-sidenav') ||
            customElements.define('sc-sidenav', sidenav);
        customElements.get('sc-upload') ||
            customElements.define('sc-upload', fileUploader);
        customElements.get('sc-login') || customElements.define('sc-login', login);
        customElements.get('sc-info-blog') ||
            customElements.define('sc-info-blog', infoBlog);
        customElements.get('sc-select-post') ||
            customElements.define('sc-select-post', selectPost);
        customElements.get('sc-lazy-wrapper') ||
            customElements.define('sc-lazy-wrapper', lazyElement);
        customElements.get('sc-files') || customElements.define('sc-files', files);
    };
    PrivateModule.ɵfac = function PrivateModule_Factory(t) { return new (t || PrivateModule)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i1.TranslateService), i0.ɵɵinject(i2.SettingsService)); };
    PrivateModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: PrivateModule });
    PrivateModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
            { provide: APP_BASE_HREF, useValue: '/' },
            { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
        ], imports: [CoreModule, DndModule, CodeEditorModule.forRoot()] });
    return PrivateModule;
}());
export { PrivateModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(PrivateModule, { declarations: [BuilderComponent,
        UserComponent,
        WarehouseComponent,
        GroupsComponent,
        SettingsComponent,
        LazyWrapperComponent,
        FileUploadComponent,
        BlogTabsComponent,
        DropdownComponent,
        InfoBlogComponent,
        SelectPostComponent,
        PropsDialogComponent,
        CreateComponentDialogComponent,
        BuilderUploadComponent,
        PagesComponent,
        PagesSettingsComponent,
        CodeEditorComponent,
        TemplatesComponent,
        TemplatesSettingsComponent,
        FilesComponent], imports: [CoreModule, DndModule, i3.CodeEditorModule] }); })();
