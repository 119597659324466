import { first } from 'rxjs';
import { environment } from 'src/environments/environment';
import jwtDecode from 'jwt-decode';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./token-state.service";
import * as i3 from "../../../shared/services/path.service";
var TokenService = /** @class */ (function () {
    function TokenService(http, tokenState, pathSvc) {
        var _this = this;
        this.http = http;
        this.tokenState = tokenState;
        this.pathSvc = pathSvc;
        this.loginUrl = environment.loginPathname;
        this.port = environment.port;
        this.domain = environment.standalone
            ? environment.domain
            : window.location.hostname;
        this.tokenState.setToken(window.accessToken);
        var token = window.accessToken;
        delete window.accessToken;
        setTimeout(function () {
            _this.renewToken(token);
        }, window.accessToken
            ? (jwtDecode(token).exp - jwtDecode(token).iat) *
                1000 -
                60000
            : 0);
    }
    TokenService.prototype.renewToken = function (accessToken) {
        var _this = this;
        setTimeout(function () {
            _this.http
                .post("".concat(_this.pathSvc.setPath(_this.domain, _this.port, true), "/global").concat(_this.loginUrl, "/renewTokens"), {})
                .pipe(first())
                .subscribe(function (value) {
                _this.tokenState.setToken(value.accessToken);
                _this.renewToken(value.accessToken);
            });
        }, accessToken
            ? (jwtDecode(accessToken).exp -
                jwtDecode(accessToken).iat) *
                1000 -
                60000
            : 0);
    };
    TokenService.prototype.logout = function () {
        return this.http.post("".concat(this.pathSvc.setPath(this.domain, this.port, true), "/global").concat(this.loginUrl, "/logout"), {});
    };
    TokenService.prototype.login = function (email, password, phone) {
        var path = "".concat(this.pathSvc.setPath(this.domain, this.port, true), "/global").concat(this.loginUrl, "/login");
        if (window.location.hostname.includes('localhost') &&
            !environment.standalone) {
            path = "http://localhost:3010/global/v1/login/login";
        }
        return this.http.post(path, {
            userData: { email: email, password: password, phone: phone },
        });
    };
    TokenService.ɵfac = function TokenService_Factory(t) { return new (t || TokenService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokenStateService), i0.ɵɵinject(i3.PathService)); };
    TokenService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TokenService, factory: TokenService.ɵfac, providedIn: 'root' });
    return TokenService;
}());
export { TokenService };
