import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingComponent } from '../components/loading/loading.component';
import { PriceDialogComponent } from '../../shared/components/price-dialog/price-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@ngneat/dialog';
import { SafeUrlPipe } from '../../shared/pipes/safeurl.pipe';
import { IntersectionObserverDirective } from '../../shared/directives/intersection-observer.directive';
import { ScrollTrackerDirective } from '../../shared/directives/scroll-tracker.directive';
import { SwiperModule } from 'swiper/angular';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from '../components/notification/notification.component';
import { TranslatePipe } from '../pipes/translate.pipe';
import * as i0 from "@angular/core";
import * as i1 from "@ngneat/dialog";
var CoreModule = /** @class */ (function () {
    function CoreModule() {
    }
    CoreModule.ɵfac = function CoreModule_Factory(t) { return new (t || CoreModule)(); };
    CoreModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: CoreModule });
    CoreModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [CommonModule,
            FormsModule,
            ReactiveFormsModule,
            DialogModule.forRoot(),
            SwiperModule,
            BrowserAnimationsModule,
            HttpClientModule, HttpClientModule,
            FormsModule,
            ReactiveFormsModule,
            SwiperModule,
            CommonModule] });
    return CoreModule;
}());
export { CoreModule };
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CoreModule, { declarations: [NotificationComponent,
        LoadingComponent,
        SafeUrlPipe,
        IntersectionObserverDirective,
        ScrollTrackerDirective,
        TranslatePipe,
        PriceDialogComponent], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule, i1.DialogModule, SwiperModule,
        BrowserAnimationsModule,
        HttpClientModule], exports: [HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingComponent,
        NotificationComponent,
        SafeUrlPipe,
        IntersectionObserverDirective,
        ScrollTrackerDirective,
        SwiperModule,
        CommonModule,
        TranslatePipe] }); })();
