import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
var PathService = /** @class */ (function () {
    function PathService() {
        this.domain = environment.domain;
        this.local = environment.local;
    }
    PathService.prototype.setPath = function (domain, port, global) {
        return "".concat(this.local && global ? 'http://' : 'https://').concat(global ? (this.local ? 'localhost' : domain) : this.domain).concat(this.local && global ? "".concat(port) : '');
    };
    PathService.ɵfac = function PathService_Factory(t) { return new (t || PathService)(); };
    PathService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PathService, factory: PathService.ɵfac, providedIn: 'root' });
    return PathService;
}());
export { PathService };
