import { __decorate } from "tslib";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as i0 from "@angular/core";
import * as i1 from "../../private/shared/services/token-state.service";
var HttpTokenInterceptor = /** @class */ (function () {
    function HttpTokenInterceptor(tokenState) {
        this.tokenState = tokenState;
    }
    HttpTokenInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        this.tokenState.currentToken$
            .pipe(untilDestroyed(this))
            .subscribe(function (token) {
            _this.token = token;
        });
        var update = {
            setHeaders: {
                Authorization: this.token,
            },
        };
        if (this.token) {
            req = req.clone(update);
        }
        return next.handle(req).pipe(catchError(function (response) {
            return throwError(response.statusText);
        }));
    };
    HttpTokenInterceptor.ɵfac = function HttpTokenInterceptor_Factory(t) { return new (t || HttpTokenInterceptor)(i0.ɵɵinject(i1.TokenStateService)); };
    HttpTokenInterceptor.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: HttpTokenInterceptor, factory: HttpTokenInterceptor.ɵfac });
    HttpTokenInterceptor = __decorate([
        UntilDestroy()
    ], HttpTokenInterceptor);
    return HttpTokenInterceptor;
}());
export { HttpTokenInterceptor };
