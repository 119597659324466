import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../../app/shared/services/path.service";
var WarehouseService = /** @class */ (function () {
    function WarehouseService(http, pathSvc) {
        this.http = http;
        this.pathSvc = pathSvc;
        this.warehouseUrl = environment.warehousePathname;
        this.port = environment.port;
    }
    WarehouseService.prototype.getProducts = function (domain, resourceDirectoryId, resourceresourceGroupId, pageSize, pageNumber, language, royalties) {
        if (royalties === void 0) { royalties = false; }
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, false), "/api/warehouse/product/?resourceDirectoryId=").concat(resourceDirectoryId, "&resourceGroupId=").concat(resourceresourceGroupId, "&pageSize=").concat(pageSize, "&pageNumber=").concat(pageNumber, "&lang=").concat(language, "&royalties=").concat(royalties));
    };
    WarehouseService.prototype.searchProducts = function (domain, resourceDirectoryId, resourceresourceGroupId, language, phrase, from, size) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, true), "/global").concat(this.warehouseUrl, "/products/search/?resourceDirectoryId=").concat(resourceDirectoryId, "&resourceGroupId=").concat(resourceresourceGroupId, "&language=").concat(language, "&phrase=").concat(phrase, "&from=").concat(from, "&size=").concat(size));
    };
    WarehouseService.prototype.multiSearchProducts = function (domain, resourceDirectoryId, resourceresourceGroupId, language, phrase, from, size, currency, type, match) {
        if (match === void 0) { match = 'contains'; }
        var url = "".concat(this.pathSvc.setPath(domain, this.port, true), "/global").concat(this.warehouseUrl, "/products/search/multi/?resourceDirectoryId=").concat(resourceDirectoryId, "&resourceGroupId=").concat(resourceresourceGroupId, "&language=").concat(language, "&phrase=").concat(phrase, "&from=").concat(from, "&size=").concat(size, "&currency=").concat(currency);
        if (type) {
            url = "".concat(url, "&type=").concat(type);
        }
        if (match) {
            url = "".concat(url, "&match=").concat(match);
        }
        return this.http.get(url);
    };
    WarehouseService.prototype.getOrderProducts = function (domain, resourceDirectoryId, orderId, language) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, true), "/global").concat(this.warehouseUrl, "/order/products/").concat(orderId, "?resourceDirectoryId=").concat(resourceDirectoryId, "&lang=").concat(language));
    };
    WarehouseService.prototype.getForm = function (domain, id, language) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, true), "/global").concat(this.warehouseUrl, "/form/").concat(id, "?lang=").concat(language));
    };
    WarehouseService.prototype.createAnswer = function (domain, resourceDirectoryId, answer) {
        return this.http.post("".concat(this.pathSvc.setPath(domain, this.port, true), "/global").concat(this.warehouseUrl, "/form/?resourceDirectoryId=").concat(resourceDirectoryId), answer);
    };
    WarehouseService.ɵfac = function WarehouseService_Factory(t) { return new (t || WarehouseService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.PathService)); };
    WarehouseService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: WarehouseService, factory: WarehouseService.ɵfac, providedIn: 'root' });
    return WarehouseService;
}());
export { WarehouseService };
