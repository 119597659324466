import { __assign, __decorate } from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment'; // Adjust the import path based on your project structure
import { NotificationType } from '../../../../app/shared/models/notification.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./token-state.service";
import * as i3 from "./infrastructure.service";
import * as i4 from "src/app/shared/services/notification.service";
var TemplatesService = /** @class */ (function () {
    function TemplatesService(http, tokenState, resourceSvc, notificationSvc) {
        var _this = this;
        this.http = http;
        this.tokenState = tokenState;
        this.resourceSvc = resourceSvc;
        this.notificationSvc = notificationSvc;
        this.resourceSvc.currentResourceDirectory$
            .pipe(untilDestroyed(this))
            .subscribe(function (directory) {
            _this.currentResourceDirectory = directory;
        });
        this.resourceSvc.currentResourceGroup$
            .pipe(untilDestroyed(this))
            .subscribe(function (group) {
            _this.currentResourceGroup = group;
        });
        this.tokenState.currentToken$
            .pipe(untilDestroyed(this))
            .subscribe(function (token) {
            _this.token = token;
        });
    }
    TemplatesService.prototype.parseJSON = function (data, keys) {
        keys.forEach(function (key) {
            if (typeof data[key] === 'string') {
                data[key] = data[key] ? JSON.parse(data[key]) : [];
            }
        });
        return data;
    };
    TemplatesService.prototype.load = function (currentId, keys) {
        var _this = this;
        var _a, _b;
        if (keys === void 0) { keys = {}; }
        var id = "".concat(this.currentResourceGroup.id, "/").concat(currentId);
        var loadUrl = "".concat(environment.protocol).concat((_a = this.currentResourceDirectory) === null || _a === void 0 ? void 0 : _a.domain).concat(environment.builderPathname, "/templates/").concat(id);
        if (currentId === 'uuidv4')
            return throwError('Invalid ID');
        return this.http
            .get(loadUrl, {
            headers: new HttpHeaders({
                Authorization: this.token,
                'group-domain': ((_b = this.currentResourceGroup) === null || _b === void 0 ? void 0 : _b.groupName) || '',
                'Content-Type': 'application/json',
            }),
            params: keys,
        })
            .pipe(map(function (data) {
            return _this.parseJSON(data, [
                'pages',
                'styles',
                'assets',
                'symbols',
                'attributes',
            ]);
        }), catchError(function (error) {
            // Handle the error by emitting a notification
            _this.notificationSvc.emitNotification('Something went wrong!', NotificationType.error);
            throw error;
        }));
    };
    TemplatesService.prototype.loadAll = function (keys) {
        var _this = this;
        var _a, _b;
        if (keys === void 0) { keys = {}; }
        var url = "".concat(environment.protocol).concat((_a = this.currentResourceDirectory) === null || _a === void 0 ? void 0 : _a.domain).concat(environment.builderPathname, "/templates/").concat(this.currentResourceGroup.id);
        return this.http
            .get(url, {
            headers: new HttpHeaders({
                Authorization: this.token,
                'group-domain': ((_b = this.currentResourceGroup) === null || _b === void 0 ? void 0 : _b.groupName) || '',
                'Content-Type': 'application/json',
            }),
            params: keys,
        })
            .pipe(map(function (data) {
            data.templates.forEach(function (template) {
                _this.parseJSON(template, [
                    'pages',
                    'styles',
                    'assets',
                    'symbols',
                    'attributes',
                ]);
            });
            return data.templates;
        }), catchError(function (error) {
            // Handle the error by emitting a notification
            _this.notificationSvc.emitNotification('Something went wrong!', NotificationType.error);
            throw error;
        }));
    };
    TemplatesService.prototype.store = function (data) {
        var _this = this;
        var _a, _b, _c;
        if (!data.id)
            return throwError('No current ID');
        ['pages', 'assets', 'styles', 'symbols', 'attributes'].forEach(function (key) {
            if (typeof data[key] !== 'string') {
                data[key] = JSON.stringify(data[key]);
            }
        });
        var id = "".concat(environment.protocol).concat((_a = this.currentResourceDirectory) === null || _a === void 0 ? void 0 : _a.domain).concat(environment.builderPathname, "/templates/").endsWith('/')
            ? "".concat(data.id, "?groupId=").concat(data.groupId || this.currentResourceGroup.id)
            : "/".concat(data.id, "?groupId=").concat(data.groupId || this.currentResourceGroup.id);
        if (this.isShared && !this.currentOriginGroupId) {
            this.currentOriginGroupId = this.currentResourceGroup.id;
        }
        var payload = __assign(__assign({}, data), { updatedAt: new Date().toISOString() });
        return this.http
            .post("".concat(environment.protocol).concat((_b = this.currentResourceDirectory) === null || _b === void 0 ? void 0 : _b.domain).concat(environment.builderPathname, "/templates/").concat(id), payload, {
            headers: new HttpHeaders({
                Authorization: this.token,
                'group-domain': ((_c = this.currentResourceGroup) === null || _c === void 0 ? void 0 : _c.groupName) || '',
                'Content-Type': 'application/json',
            }),
        })
            .pipe(map(function (response) {
            if (response.id === 'clone') {
                var event_1 = new CustomEvent('clone', {
                    detail: __assign(__assign({}, data), payload),
                });
                window.dispatchEvent(event_1);
            }
            return response;
        }), catchError(function (error) {
            // Handle the error by emitting a notification
            _this.notificationSvc.emitNotification('Something went wrong!', NotificationType.error);
            throw error;
        }));
    };
    TemplatesService.prototype.delete = function (index) {
        var _this = this;
        var _a;
        var urlDelete = "".concat(process.env.DIRECTORY_DOMAIN, "/api/builder/templates");
        var id = index || this.currentId;
        id = urlDelete.endsWith('/')
            ? id
            : "/".concat(id, "?groupId=").concat(this.currentResourceGroup.id);
        return this.http
            .delete("".concat(urlDelete).concat(id), {
            headers: new HttpHeaders({
                Authorization: this.token,
                'group-domain': ((_a = this.currentResourceGroup) === null || _a === void 0 ? void 0 : _a.groupName) || '',
                'Content-Type': 'application/json',
            }),
        })
            .pipe(catchError(function (error) {
            // Handle the error by emitting a notification
            _this.notificationSvc.emitNotification('Something went wrong!', NotificationType.error);
            throw error;
        }));
    };
    TemplatesService.ɵfac = function TemplatesService_Factory(t) { return new (t || TemplatesService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokenStateService), i0.ɵɵinject(i3.InfrastructureService), i0.ɵɵinject(i4.NotificationService)); };
    TemplatesService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TemplatesService, factory: TemplatesService.ɵfac, providedIn: 'root' });
    TemplatesService = __decorate([
        UntilDestroy()
    ], TemplatesService);
    return TemplatesService;
}());
export { TemplatesService };
