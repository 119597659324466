import { environment } from '../../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "src/app/shared/services/path.service";
import * as i2 from "@angular/common/http";
var PublicBlogService = /** @class */ (function () {
    function PublicBlogService(pathSvc, http) {
        this.pathSvc = pathSvc;
        this.http = http;
        this.port = environment.port;
        this.blogSource = new BehaviorSubject([]);
        this.posts$ = this.blogSource.asObservable();
    }
    PublicBlogService.prototype.list = function (domain, groupId, language, from, size) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, true), "/global/v1/blog/post/?groupId=").concat(groupId, "&language=").concat(language, "&from=").concat(from, "&size=").concat(size));
    };
    PublicBlogService.prototype.listArray = function (domain, postIds, language) {
        return this.http.post("".concat(this.pathSvc.setPath(domain, this.port, true), "/global/v1/blog/post/posts/?language=").concat(language), postIds);
    };
    PublicBlogService.prototype.read = function (domain, id, language) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, true), "/global/v1/blog/post/").concat(id, "?language=").concat(language));
    };
    PublicBlogService.prototype.searchBeforeAfter = function (domain, groupId, language, size, date) {
        return this.http.get("".concat(this.pathSvc.setPath(domain, this.port, true), "/global/v1/blog/post/search/post/?groupId=").concat(groupId, "&language=").concat(language, "&size=").concat(size, "&date=").concat(date));
    };
    PublicBlogService.prototype.setPosts = function (posts) {
        this.blogSource.next(posts);
    };
    PublicBlogService.prototype.clearPosts = function () {
        var currentPosts = this.blogSource.value;
        currentPosts = [];
        this.blogSource.next(currentPosts);
    };
    PublicBlogService.ɵfac = function PublicBlogService_Factory(t) { return new (t || PublicBlogService)(i0.ɵɵinject(i1.PathService), i0.ɵɵinject(i2.HttpClient)); };
    PublicBlogService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: PublicBlogService, factory: PublicBlogService.ɵfac, providedIn: 'root' });
    return PublicBlogService;
}());
export { PublicBlogService };
